<template>
  <component
    :is="safeType"
    v-if="safeType && useCheckConditions(conditions)"
    v-bind="safeAttrs"
    :label="label"
    @click="doAction"
  >
    {{ label }}
  </component>

  <v-tooltip
    v-else-if="(tooltip || (icon && label)) && useCheckConditions(conditions)"
    :text="tooltip || (icon && label)"
  >
    <template #activator="{ props: tooltipProps }">
      <v-btn
        v-bind="{ ...tooltipProps, ...safeAttrs }"
        :text="label"
        @click="doAction"
      />
    </template>
  </v-tooltip>

  <v-btn
    v-else-if="useCheckConditions(conditions)"
    v-bind="safeAttrs"
    :text="label"
    @click="doAction"
  />
</template>

<script>
import { defineAsyncComponent, defineComponent } from 'vue';
import { includes, startsWith } from 'lodash-es';
import { useCheckConditions } from '@matterific/utils';

export default defineComponent({
  name: 'MtfAction',
  components: {
    MtfSocial: defineAsyncComponent(() => import('@matterific/components/Social.vue')),
    MtfAvatar: defineAsyncComponent(() => import('@matterific/components/Avatar.vue'))
  },
  inheritAttrs: true,
  customOptions: {},

  props: {
    conditions: Array,
    to: [String, Object],
    href: String,
    icon: [String, Object],
    label: [String, Object],
    tooltip: String,
    template: String,
    disabled: {
      type: Boolean,
      default: false
    }
  },

  emits: [],

  setup() {
    return {
      useCheckConditions
    };
  },

  computed: {
    safeAttrs() {
      return {
        ...this.$attrs,
        href: this.safeHref,
        target: this.safeTarget,
        to: this.safeTo,
        icon: this.icon,
        class: 'max-w-100',
        disabled: this.disabled
        // appendIcon: isExternalURL.value ? 'mdi-open-in-new' : null
      };
    },

    safeType() {
      return this.template ? `mtf-${this.template}` : null;
    },

    isComponent() {
      return !includes(['button'], this.template);
    },

    isExternalURL() {
      try {
        // return new URL(this.to).origin !== location.origin;
        return (!this.to && this.href) || new URL(this.to).origin !== location.origin;
      } catch {
        return false;
      }
    },

    isHash() {
      return startsWith(this.href, '#');
    },

    safeHref() {
      return this.isExternalURL.value ? this.href : null;
    },

    safeTo() {
      return !this.isExternalURL.value ? this.to : null;
    },

    safeTarget() {
      return this.isExternalURL.value ? '_blank' : null;
    }
  },

  methods: {
    doAction() {
      // implement soft scroll
      if (this.isHash) {
        const el = document.querySelector(this.href);
        if (!el) return;
        if ('scrollBehavior' in document.documentElement.style) {
          window.scrollTo({ top: el.offsetTop, behavior: 'smooth' });
        } else {
          window.scrollTo(0, el.offsetTop);
        }
      }

      // todo integrate analytics
      // if (this?.$gtm?.push) {
      //   this.$gtm.push({
      //     event: null, // Event type [default = 'interaction'] (Optional)
      //     category: 'Action',
      //     action: 'click',
      //     label: this.action || this.channel,
      //     value: this.to,
      //     noninteraction: false // Optional
      //   });
      // }
    }
  }
});
</script>
