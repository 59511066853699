<template>
  <div
    v-if="hasActions"
    class="mx-n1"
  >
    <mtf-action
      v-for="(action, name) in actions"
      :key="`action-${name}`"
      v-bind="action"
      :to="useSafeValue(action.to, item)"
      :class="actions.length == 1 || action?.block ? '' : 'mx-1'"
      :disabled="disabled || action?.disabled"
      @click="
        $emit('action', {
          command: action.command,
          context: item,
          event: action.event
        })
      "
    />
  </div>
</template>

<script>
import { isEmpty } from 'lodash-es';
import { useSafeValue } from '@matterific/utils';

import mtfAction from './Action.vue';

export default defineComponent({
  name: 'MtfActions',
  components: {
    mtfAction
  },
  inheritAttrs: true,
  customOptions: {},

  props: {
    actions: {
      type: Object,
      default: () => ({})
    },
    item: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  emits: ['action'],

  setup() {
    return {
      useSafeValue
    };
  },

  computed: {
    hasActions() {
      return !isEmpty(this.actions);
    }
  }
});
</script>
